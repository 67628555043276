'use client';

import AppPageContext from '../context/app-page-context';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from '../hooks/use-translation';
import { kImageFolderUrl } from '../services/constants';
import ImgWithPlaceholder from './shared/img-with-placeholder';
import { useClassName } from '../hooks/use-class-name';
import { getQueryParam } from '../hooks/use-get-query-param';
import { DownloadSection } from './download-section/download-section';

export const AppHeader = (): JSX.Element => {
  const { app } = useContext(AppPageContext);

  const {
    title,
    iconUrl,
    author,
    isFree,
    doesContainsAds,
    isPremium,
    shortDescription,
  } = app;
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const headerHolder = useRef<HTMLDivElement>(null);
  const downloadBtn = useRef<HTMLAnchorElement>(null);
  const effectRan = useRef<boolean>(false);

  useEffect(() => {
    const onScroll = (): void => {
      const distanceToTop = headerHolder.current.getBoundingClientRect().top;
      // 86 is the header height
      if (distanceToTop < 86 && window.innerWidth > 1024) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isSticky]);

  useEffect(() => {
    if (effectRan.current) return; // Prevent execution on subsequent renders
    effectRan.current = true;

    const startDownload = getQueryParam(location.search, 'startdownload');
    if (startDownload === 'true') {
      setTimeout(() => {
        handleStartDownloadParam();
      }, 500);
    }
  }, []);

  const handleStartDownloadParam = (): void => {
    downloadBtn.current.click();
  };

  const stickyHeaderClassNames = useClassName(
    {
      floats: isSticky,
    },
    'app-info-sticky-header'
  );
  const { t } = useTranslation();

  const appAuthorName = app.richDetails?.authorNameOverride
    ? app.richDetails.authorNameOverride
    : author;

  return (
    <section className='app-header-section'>
      <div className='app-header-content' ref={headerHolder}>
        <div className={stickyHeaderClassNames}>
          <div className='app-info-sticky-header-inner'>
            <div className='app-info'>
              <div className='app-thumb'>
                <ImgWithPlaceholder
                  width={56}
                  height={56}
                  img={iconUrl}
                  placeholder={`${kImageFolderUrl}/app-page/app-icon-placeholder.webp`}
                  alt={title}
                />
              </div>
              <div className='app-title'>
                <h1>{title}</h1>
                <span>
                  <span>{t('by')}</span> {appAuthorName}
                </span>
              </div>
            </div>

            <div className='app-download-area'>
              <DownloadSection item={app} />
              {/* <a
                href={downloadUrl}
                onClick={handleClickDownload}
                className='btn-primary'
                download={t('appInstaller', { title })}
                ref={downloadBtn}
              >
                {t('downloadApp')}
              </a> */}
              <ul className='app-inventory-list'>
                {isFree && (
                  <li className='app-inventory-item'>{t('freeOption')}</li>
                )}
                {doesContainsAds && (
                  <li className='app-inventory-item'>{t('containsAds')}</li>
                )}
                {isPremium && (
                  <li className='app-inventory-item'>
                    {t('subscriptionOption')}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='app-description'>
        <p dangerouslySetInnerHTML={{ __html: shortDescription }} />
      </div>
    </section>
  );
};
