'use client';

import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from '../../hooks/use-translation';
import { kImageFolderUrl } from '../../services/constants';
import SpriteIcon from '../shared/sprite-icon';
import AppPageContext from '../../context/app-page-context';
import { useClassName } from '../../hooks/use-class-name';
import { useEmailValidation } from '../../hooks/use-email-validation';
import { useRemindMeService } from '../../hooks/use-remind-me-service';
import { useAnalytics } from '../../hooks/use-analytics';
import ImgWithPlaceholder from '../shared/img-with-placeholder';
import { title } from 'process';
import { dispatch } from '../../hooks/use-bus';
import { AppBusEventType } from '../../interfaces/bus-events';
import Utils from '../../utils/utils';
import useIsMobile from '../../hooks/use-is-mobile';
import { useOS } from '../../hooks/use-os';

export const RemindMeMobile = (): JSX.Element => {
  const { t } = useTranslation();
  const { app } = useContext(AppPageContext);
  const { isLoading, sendRemindMe } = useRemindMeService();
  const analytics = useAnalytics();
  const { validate } = useEmailValidation();
  const [searchParams, setSearchParams] = useState<URLSearchParams>();
  const isMobile = useIsMobile();
  const OS = useOS();

  const emailField = useRef<HTMLInputElement>(null);

  const [showRemindMeWindow, setShowRemindMeWindow] = useState<boolean>(false);
  const [remindMeButtonDisabled, setRemindMeButtonDisabled] =
    useState<boolean>(true);

  const [emailValidationActive, setEmailValidationActive] =
    useState<boolean>(false);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setSearchParams(params);
    if (params.has('getapp')) {
      setShowRemindMeWindow(true);
    }

    if (emailField.current) {
      emailField.current.focus();
    }
  }, []);

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    doEmailValidation();

    const payload = {
      email: emailField.current.value,
      type: searchParams.get('type'),
      appTitle: searchParams.get('appTitle') ?? app.title,
      appId: searchParams.get('appId') ?? app.id,
      id: searchParams.get('id') ?? app.id,
      title: searchParams.get('title') ?? app.title,
      utmSource: searchParams.get('utm_source'),
      utmMedium: searchParams.get('utm_medium'),
      utmCampaign: searchParams.get('utm_campaign'),
      utmTerm: searchParams.get('utm_term'),
      utmContent: searchParams.get('utm_content'),
    };

    analytics.track('appstore_mobile_email_reminder_sent', {
      appid: app.id ?? 'N/A',
    });
    const success = await sendRemindMe(payload);
    setShowSuccess(success);
  };

  const doEmailValidation = (): boolean => {
    const isValid = validate(emailField.current.value);
    if (isValid) {
      setRemindMeButtonDisabled(false);
      setEmailValidationActive(false);
      return true;
    } else {
      setEmailValidationActive(true);
      setRemindMeButtonDisabled(true);
      return false;
    }
  };

  const handleToggleReminder = (): void => {
    setShowRemindMeWindow(cur => !cur);
    emailField.current.focus();
  };

  const downloadUrl = app.alternativeDownloads[0].url;

  const handleClickDownload = (event: React.MouseEvent<HTMLElement>): void => {
    const url = Utils.InstallerDownloadOverwrite(event, app.id);
    analytics.track('appstore_download_clicked', {
      appId: app.id,
      appName: title,
      component: 'App one page',
      platform: 'Web',
    });

    analytics.gtagPush({
      event: 'app_download_cta',
      app_name: title,
      app_id: app.id,
      app_os: OS,
    });

    dispatch({
      type: AppBusEventType.App.ShowDownloadModal,
      data: { isOpen: true, currentApp: app, url },
    });
  };

  const className = useClassName(
    {
      'is-active': showRemindMeWindow,
    },
    'remind-me-window',
    [showRemindMeWindow]
  );

  const emailValidationClassName = useClassName(
    {
      active: emailValidationActive,
    },
    'email-validation tooltip-alert',
    [emailValidationActive]
  );

  const buttonClassName = useClassName(
    {
      loading: isLoading,
    },
    'remind-me btn-primary',
    [isLoading]
  );

  const formClassName = useClassName(
    {
      'is-shown': !showSuccess,
    },
    '',
    [showSuccess]
  );

  const successStateClassName = useClassName(
    {
      'is-shown': showSuccess,
    },
    'success-state',
    [showSuccess]
  );

  return (
    <section className='remind-me-window-section'>
      <div className={className}>
        <form
          onSubmitCapture={handleSubmit}
          className={formClassName}
          id='Form'
          noValidate
        >
          <fieldset>
            <img
              src={`${kImageFolderUrl}/app-page/remmind-me.svg`}
              className='asset'
              alt='remind me'
            />
            <p dangerouslySetInnerHTML={{ __html: t('nonMobile') }} />
            <p className='validation-form hidden' id='FormValidation'>
              {t('wentWrong')}
            </p>

            <div className='input-field'>
              <input
                ref={emailField}
                type='email'
                placeholder='Add your email here'
                name='email'
                id='email-input'
                required
                onKeyUp={doEmailValidation}
              />
              <div className={emailValidationClassName}>
                <span className='alert-message'>{t('enterValidEmail')}</span>
              </div>
            </div>

            <button
              className={buttonClassName}
              id='RemindMe'
              disabled={remindMeButtonDisabled}
            >
              <SpriteIcon icon='loader' />
              {t('remindMe')}
            </button>
          </fieldset>
        </form>

        <div className={successStateClassName} id='SuccessState'>
          <div className='success-state-inner'>
            <SpriteIcon icon='love' />
            <p>{t('reminderSent')}</p>
          </div>
        </div>

        <button
          className='clean-icon-btn close-window'
          id='CloseWindow'
          onClick={handleToggleReminder}
        >
          <SpriteIcon icon='closeSmall' />
        </button>
      </div>

      <div className='get-the-app-area'>
        <div className='app-info'>
          <div className='app-thumb'>
            <ImgWithPlaceholder
              width={40}
              height={40}
              img={app.iconUrl}
              placeholder={`${kImageFolderUrl}/app-page/app-icon-placeholder.webp`}
              alt={app.title}
            />
          </div>
          <div className='app-title'>
            <h6>{app.title}</h6>
          </div>
        </div>

        {isMobile && (
          <button
            className='get-app btn-primary'
            id='GetApp'
            onClick={handleToggleReminder}
          >
            {t('getApp')}
          </button>
        )}

        {!isMobile && (
          <a
            href={downloadUrl}
            className='get-app btn-primary'
            id='GetApp'
            onClick={handleClickDownload}
          >
            {t('getApp')}
          </a>
        )}
      </div>
    </section>
  );
};
