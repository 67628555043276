'use client';
import { useContext, useEffect } from 'react';
import { useAnalytics } from '../../../hooks/use-analytics';
import AppPageContext from '../../../context/app-page-context';
import Utils from '../../../utils/utils';

export const AppTracking = (): JSX.Element  => {
  const analytics = useAnalytics();
  const { app } = useContext(AppPageContext);

  useEffect(()=> {
    analytics.track('appstore_app_one_page_initialized', {
      appId: app.id,
      platform: Utils.isMobile() ? 'Mobile' : 'Web',
    });
  }, []);

  return (
    <>
    </>
  );
};
