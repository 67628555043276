import { FC, useContext, useEffect, useRef, useState } from 'react';
import SpriteIcon from '../../shared/sprite-icon';
import { useTranslation } from '../../../hooks/use-translation';
import AppPageContext from '../../../context/app-page-context';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { useClassName } from '../../../hooks/use-class-name';
import { useSliderImageSorting } from '../../../hooks/use-slider-image-sorting';

// -----------------------------------------------------------------------------
interface Props {
  slideIndex: number;
  onClickResetSlideIndex: () => void;
}

// -----------------------------------------------------------------------------
export const FullScreenImageSliderModal: FC<Props> = ({
  slideIndex,
  onClickResetSlideIndex
}): JSX.Element => {

  const { app } = useContext(AppPageContext);
  const slides = useSliderImageSorting(app.screenshotUrls);

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const fullScreenImageSlider = useRef<HTMLDivElement>(null);
  const navigationNext = useRef<HTMLButtonElement>(null);
  const navigationPrev = useRef<HTMLButtonElement>(null);

  useEffect(() => {

    const fullScreenImageSliderConfig =
      new Swiper(fullScreenImageSlider.current, {
        slidesPerView: 'auto',
        speed: 700,
        centeredSlides: true,
        direction: 'horizontal',
        modules: [Navigation],
        navigation: {
          nextEl: navigationNext.current,
          prevEl: navigationPrev.current,
        }
      });

    if (slideIndex !== -1) {
      fullScreenImageSliderConfig.slideTo(slideIndex, 0);
      setIsOpen(true);
    }

    document.addEventListener('keydown', (e: any) => {
      if (e.key === 'Escape') {
        handleSlideIndexReset(e);
      }
    });

  }, [slideIndex]);

  const handleSlideIndexReset = (e: any): void => {
    if (e.target.tagName === 'IMG') return;
    if (e.target.classList.contains('slider-nav')) return;

    setIsOpen(false);
    onClickResetSlideIndex();
  };

  const className = useClassName({
    'modal-is-open': isOpen
  }, 'full-screen-image-slider-section');

  return (
    <section className={className} onClick={e => handleSlideIndexReset(e)}>
      <div className='full-screen-image-slider-wrapper'>
        <div
          className='swiper full-screen-image-slider'
          ref={fullScreenImageSlider}
        >
          <div className='slider-actions'>
            {app.screenshotUrls && app.screenshotUrls?.length > 1 &&
              <>
                <button
                  className='full-screen-image-slider-next slider-nav'
                  ref={navigationNext}
                >
                  <SpriteIcon icon='sliderArrow' />
                </button>
                <button
                  className='full-screen-image-slider-prev slider-nav'
                  ref={navigationPrev}
                >
                  <SpriteIcon icon='sliderArrow' />
                </button>
              </>
            }
          </div>
          <ul className='full-screen-images-list swiper-wrapper'>
            {
              slides && slides.map((slide, index) => {
                return (
                  <li className='swiper-slide' key={index}>
                    <img
                      src={slide.asset}
                      alt={`${app.title} screenshot image`}
                    />
                  </li>
                );
              })
            }
          </ul>
          <div className='full-view-actions'>
            <button className='clean-btn' 
              onClick={handleSlideIndexReset}
            >
              <SpriteIcon icon='closeSmall' />
              {t('close')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
