'use client';
import { FC, MouseEvent, useState, useEffect } from 'react';
import { useAnalytics } from '../../hooks/use-analytics';
import { dispatch } from '../../hooks/use-bus';
import { useOS } from '../../hooks/use-os';
import { useTranslation } from '../../hooks/use-translation';
import { AppBusEventType } from '../../interfaces/bus-events';
import Utils from '../../utils/utils';
import { getQueryParam } from '../../hooks/use-get-query-param';
import { OwApp } from '../../interfaces/domain/ow-app.modal';
import SpriteIcon from '../shared/sprite-icon';
import { useClassName } from '../../hooks/use-class-name';
import { MainDownloadBtn } from './main-dl-btn';
import { MultiDownloadList } from './multi-dl-list';

interface DownloadItemProps {
  item: OwApp;
}
// -----------------------------------------------------------------------------

export const DownloadSection: FC<DownloadItemProps> = ({
  item,
}): JSX.Element => {
  const extensionId = item.id;
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const OS = useOS();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const mainDownloadUrl = new URL(item.alternativeDownloads[0].url);
  mainDownloadUrl.searchParams.append('utmContent', 'new-light');

  // ---------------------------------------------------------------------------
  const handleClickDownload = (
    event: MouseEvent<HTMLElement>,
    overrideUrl: string = null
  ): void => {
    const url = Utils.InstallerDownloadOverwrite(
      event,
      extensionId,
      overrideUrl
    );

    const autoDownload = getQueryParam(location.search, 'startdownload')
      ? true
      : false;

    analytics.track('appstore_download_clicked', {
      appId: extensionId,
      appName: item.title,
      component: 'App one page',
      platform: 'Web',
      autoDownload: autoDownload,
    });

    analytics.gtagPush({
      event: 'app_download_cta',
      app_name: item.title,
      app_id: extensionId,
      app_os: OS,
    });

    handleShowDownloadModal(Utils.appendHTTPSIfMissing(url));
  };

  // ---------------------------------------------------------------------------
  const handleShowDownloadModal = (url: string): void => {
    dispatch({
      type: AppBusEventType.App.ShowDownloadModal,
      data: {
        isOpen: true,
        currentApp: item,
        url,
      },
    });
  };

  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (item.alternativeDownloads.length === 1) return;

    const handleCloseMenu = (e: any): void => {
      const elm = e.target;
      if (
        elm.classList.contains('option-item-url') ||
        elm.classList.contains('multi-options-btn')
      )
        return;
      setIsOpen(false);
    };

    document.addEventListener('mousedown', handleCloseMenu);
    window.addEventListener('scroll', () => {
      setIsOpen(false);
    });

    return () => {
      document.removeEventListener('mousedown', handleCloseMenu);
      window.removeEventListener('scroll', () => {
        setIsOpen(false);
      });
    };
  }, []);

  // ---------------------------------------------------------------------------
  const multiClassName = useClassName(
    {
      'is-open': isOpen,
    },
    'multi-downloads-menu'
  );

  return (
    <div className='download-actions'>
      {item.alternativeDownloads?.length === 1 && (
        <MainDownloadBtn
          appTitle={item.title}
          btnLabel={item.alternativeDownloads[0].label}
          url={mainDownloadUrl.toString()}
          className='dl-btn btn-primary'
          handleClickDownload={handleClickDownload}
        />
      )}

      {item.alternativeDownloads?.length > 1 && (
        <div className={multiClassName}>
          <div className='main-multi-btn'>
            <MainDownloadBtn
              appTitle={item.title}
              btnLabel={item.alternativeDownloads[0].label}
              url={mainDownloadUrl.toString()}
              className='multi-dl-btn'
              icon={item.alternativeDownloads[0].platform}
              handleClickDownload={handleClickDownload}
            />

            <button
              className='multi-options-btn'
              onClick={() => setIsOpen(!isOpen)}
              title={t('downloadOptions')}
            >
              <SpriteIcon icon='arrow-down' svgClassName='arrow-icon' />
            </button>
          </div>

          <MultiDownloadList
            appTitle={item.title}
            dlData={item.alternativeDownloads}
            skipMain={true}
            handleClickDownload={handleClickDownload}
          />
        </div>
      )}
    </div>
  );
};
