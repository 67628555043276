import { OWAppRichScreenshots, OWAppScreenshots } from '../interfaces/domain/ow-app.modal';

export const useSliderImageSorting = (array:
  OWAppScreenshots[] |
  OWAppRichScreenshots[]
): any => {
  if (!array || array.length === 0) {
    return array;
  }

  return array.sort((a, b) => {

    if (a.type === 'video' && b.type !== 'video') {
      return -1;
    }

    if (a.type !== 'video' && b.type === 'video') {
      return 1;
    }

    return a.sortOrder - b.sortOrder;
  });
};